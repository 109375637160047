.header {
    width: 100%;
    max-width: 1450px;
    display: flex;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    .brand-logo {
        padding: 0.5rem;
        font-size: 2rem;
        font-weight: 900;
        word-spacing: 0;
        a {
            text-decoration: none;
            color: var(--text-primary);
        }
    }
    .actions {
        padding: 0.5rem;
        display: flex;
        a {
            text-decoration: none;
            color: var(--text-secondary);
            &:hover {
                color: var(--text-primary);
            }
            &:focus {
                color: var(--text-primary);
            }
        }
    }
}
