.bg-dot {
    width: 100%;
    margin: 1rem 0rem;
    height: 10rem;
    display: block;
    border: 1px solid #22222a;
    opacity: 0.55;
    background-image: url("../../public/images/poklaDot.svg");
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: 700;
    .journey-title {
        transition-duration: 1s;
        transform: scale(1);
        &:hover {
            transition-duration: 1s;
            transform: scale(1.2);
        }
        @media screen and (max-width: 590px) {
            &:hover {
                transform: scale(1);
            }
        }
    }
}
.journey {
    box-sizing: border-box;
    max-width: 1450px;
    @media screen and (max-width: 1218px) {
        .steps {
            &:nth-child(2n-1) {
                .step-image {
                    display: none;
                }
            }
        }
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    border-radius: 2rem;
    // margin: 1rem;
    .steps {
        box-sizing: border-box;
        padding: 0.5rem;
        &:last-child {
            margin-bottom: 2rem;
        }
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        .steps-in-text {
            max-width: 50rem;
            align-items: flex-start;
            h2 {
                margin-bottom: 24px;
                font-size: 2.75rem;
                font-weight: 700;
            }
            p {
                line-height: 1.4em;
                font-weight: 300;
            }
        }
        .step-image {
            display: flex;
            justify-content: center;
            .hero-image-content {
                height: fit-content !important;
                width: fit-content !important;
            }
        }
    }
}
