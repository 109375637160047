#svg-icon {
    height: 1.5rem;
    width: 1.5rem;
}

#svg-icon.s,
#svg-icon.small {
    height: 1rem;
    width: 1rem;
}

#svg-icon.m,
#svg-icon.medium {
    height: 2rem;
    width: 2rem;
}

#svg-icon.l,
#svg-icon.large {
    height: 3rem;
    width: 3rem;
}

.sample-image{
    height: 20rem;
    width: 20rem;
}
.hero-image-content {
    height: fit-content !important;
    width: fit-content !important;
}
.limitpx {
    height: 20rem;
    width: 20rem;
}
.teddy{
    width: 15rem;
}