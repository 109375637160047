#main-app {
	height: 100%;
	max-width: 100%;
}

#main-app {
	--background: #FFFFFF;
	--text-primary: #1B2036;
	--text-secondary: #8A8A8A;
	--accent: #E9F7FA;
	--border: #D8DCDF;
	--call-to-action: #FF401B;
	--accent-secondary: #7FCE9A;
	--form-bg: #CBF1F5;
}

#main-app[theme='dark'] {
	--background: #222831;
	--text-primary: #EEEEEE;
	--text-secondary: #00ADB5;
	--accent: #393E46;
	--border: #00ADB5;
	--call-to-action: #912323;
	--accent-secondary: #4b515a;
	--warning: #e87c03;
}

#main-app {
	background: var(--background);
}