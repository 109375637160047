.messageStrip {
    z-index: 10;
    position: relative;
    box-sizing: border-box;
    margin: 0.5rem;
    width: 90%;
    color: var(--text-primary);
    background: var(--accent-secondary);
    padding: 0.15rem 0.5rem;
    border: solid 1px;
    border-radius: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    div {
        margin-bottom: auto;
        margin-top: auto;
        height: 1.5rem;
    }

    div {

        button {
            background: transparent;
            border: none;
            height: 100%;
            padding: 0px;
            width: 100%;

            &:hover {
                transform: scale(1.2);
            }

            span {
                height: 1rem;
            }
        }
    }
}