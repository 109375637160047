.hero-intro {
    max-width: 1450px;
    min-height: 30rem;
    font-size: 2rem;
    display: grid;
    border-radius: 2rem;
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    .hero-text {
        max-width: 60%;
        @media screen and (max-width: 800px) {
           max-width: 100%;
        }
        h1 {
            font-weight: 900;
            font-size: 4rem;
            margin: 0 0 0.2rem 0;
        }
        .title {
            color: #0677d4;
            font-size: 1.25rem;
            font-family: "Kalam", cursive;
            line-height: 1.25em;
            font-weight: 700;
        }
        p {
            font-size: 1rem;
            line-height: 1.5em;
            font-weight: 300;
            margin-bottom: 0rem;
        }
        .hero-call-to-action {
            background-color: #0677d4;
            border-radius: 0.5rem;
            color: #fff;
            text-decoration: none;
            margin: 0;
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: capitalize;
            padding: 0.5rem 2rem;
        }
    }
    .hero-image {
        .hero-image-content {
            height: fit-content !important;
            width: fit-content !important;
        }
    }
}
