.homepage {
    div {
        box-sizing: border-box;
    }

    min-height: 100vh;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: var(--text-primary);

    .homepage-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1 0 auto;
        width: 100%;

        .comming-soon {
            box-sizing: border-box;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            .comming-soon{

            }
            .fixed-carosel {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                .image-holder{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 25rem;
                }
            }
        }
    }
}