.footer {
    background-color: #07182b;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #fff;
    .contact {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            margin-left: 5rem;
            color: #fff;
            text-decoration: none;
        }
    }
    .copyrights {
        margin: 2rem auto;
    }
}
